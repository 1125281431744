<template>
    <div>
    <transaction-status :showPendingUnstake="showPendingUnstake" :showSuccessUnstake="showSuccessUnstake" @close="showPendingUnstake = false, showSuccessUnstake = false" />
    <loader v-if='showLoader && currentAddress' />
    <main class="cabinet">
      <div class="container">
        <div class="cabinet-header">
            <div class="h1">{{lang.get("CABINET_WELCOME_TITLE")}}</div>
              <cabinet-navigation />
        </div>
        <div class="wrapper-content">
            <div class="sidebar">
              <div class="card">
                
                <div class="block_sidebar">
                    <div class="block">
                        <div class="block_title">
                            <div class="title">{{lang.get("SIDEBAR_TITLE")}}</div>
                        </div>
                        <div class="h4">
                          {{lang.get("SIDEBAR_SUBTITLE")}}
                        </div>
                    </div>
                </div>
                <button @click="$router.push({name: 'pool'})"  data-cy='button_stake-new-pool' class="button button_submit-input">{{lang.get("SIDEBAR_TITLE")}}</button>
            
              </div>
              
            </div>
        <div v-if="!stakesInfo || !stakesInfo.length || !activeStakes.length" class="card card_empty">
          <div class="img"></div>
          <div class="h4">{{lang.get("NO_ACTIVE_STAKES")}}</div>
        </div>
        <div v-else class="wrapper-content">          
          <div v-for="(stake, index) in activeStakes" :key="stake.startTime" >
            <div class="card card_stake wrapper_card">
              <div class="card_stake-top">
                <div>
                  <div class="h2">{{lang.get("MY_STAKE")}} | POOL {{stake.poolVersion}}</div>
                  <div class="token">
                    <div class="token_img"><div :class="stake.name"></div></div>
                    <div class="token_title">{{stake.stakeAmount.toFixed(2)}}</div>
                  </div>

                  <div class="description">
                    <div class="title-description  title-with-addinfo">{{lang.get("EXPECTED_REWARD")}}
                      <i class="i-inform ">
                      <div class="block-hover-descr">
                        <div class="hover-descr-title">Expected Return of 90 days</div>
                        <p v-if="validReferrer(stake)" style="color:#5FDA9A;"> ({{lang.get("REFERRER_REWARD")}} +5%)</p>
                        <div class="hover-table">
                          <div v-for="(farmToken, idx) in stake.farmTokensList" :key="farmToken.name" class="hover-tr">
                                <div class="hover-td">{{tokenTicker(farmToken.name)}}</div>
                                <div class="hover-td">{{stake.farmTokensPossibleReward[idx] ? Number(stake.farmTokensPossibleReward[idx]).toFixed(4) : "0.0000"}}</div>
                          </div>
                        </div>
                        </div></i>
                      </div>
                  </div>   
                  <div  class="pool-reward">
                    <div v-for="farmToken in stake.farmTokensList" :key="farmToken.name" class="token_img"><div :class="farmToken.name"></div></div>
                  </div>                          
                 
                  <div v-if="stake.stakeAmount != 0" class="container-button">
                     <button @click="unstake(stake)" class="button button_unstake-claim"  data-cy='button_unstake-claim'>{{lang.get("UNSTAKE")}} &#38; {{lang.get("CLAIM")}} </button>
                  </div>
                </div>
                <div> 
                  
                  <div class="h2 ">{{lang.get("DAYS_STAKED")}}
                    
                  </div>
                  <div class="h1">
                    {{getTimePassed(stake.startTime)[0]}}{{lang.get(`${getTimePassed(stake.startTime)[1]}`)}} {{lang.get(`AGO`)}}
                  </div>
                  <div class=" pool-block">
                    <div class="description">
                      <div class="title-description">{{lang.get("APY")}} </div>
                      <div class="value-description">120-170%</div>
                    </div>
                    <div class="description">
                      <div class="title-description">{{lang.get("MAX_STAKE_LIMIT")}} </div>
                      <div class="value-description">{{stake.maxStake.toLocaleString()}}</div>
                    </div>
                    <div class="description">
                      <div class="title-description">{{lang.get("LOCK_IN")}} </div>
                      <div class="value-description">{{stake.isActive ? "Open" : "Close"}}</div>
                    </div>
                    <div class="description">
                      <div class="title-description">{{lang.get("NETWORK")}} </div>
                      <div class="value-description">{{lang.get("ETHEREUM")}} </div>
                    </div>
                  </div>
                  </div>
              </div>
              <div>
                <ul class="list-expected_reward" data-cy="list-expected_reward-stake">
                  <li v-for="(farmToken, idx ) in stake.farmTokensList" :key="farmToken.name"> 
                    <div class="token_img">
                      <div :class="farmToken.name"></div>
                    </div>
                    <div class="title-description">{{tokenTicker(farmToken.name)}} </div>
                    <div class="value-description">{{stake.farmTokensReward[idx] ? Number(stake.farmTokensReward[idx]).toFixed(4) : "0.0000" }}</div>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
        
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MultiLang from '@/core/multilang'; 
import TransactionStatus from '../../../components/modalWindows/TransactionStatus.vue';
import {mapState} from 'vuex';
import CabinetNavigation from '../../../components/CabinetNavigation.vue';
import Config from '../../../../Config.json'
import Loader from '../../../components/Loader.vue';

export default {
  components: { TransactionStatus, CabinetNavigation,
    Loader },
    name:"Stake",
  data: function() {
    return {
      lang: new MultiLang(this),
      showPendingUnstake:false,
      showSuccessUnstake:false,



    }
  },
 
  computed:{
    ...mapState(['stakesInfo', 'currentAddress', 'farmTokens']), //TODO set user stake info to store
    showLoader() {
      return this.stakesInfo == null ? true : false
    },
    activeStakes() {
      return this.stakesInfo.filter(el => el.isActive == true)
    }
  },
  methods: {
    validReferrer(stake){
      return (stake.referrer != "0x0000000000000000000000000000000000000000")
    },
    tokenTicker(tokenName) {
        return Config.tokenAliases[tokenName];
      },
    async unstake(stake) {

      try {

        let rawUnStakeTransaction = await this.$root.core.unstake(this.currentAddress, stake.stakeId, stake.poolVersion);

        this.showPendingUnstake=true
        let resultUnStake = await rawUnStakeTransaction.wait();

            if(resultUnStake.transactionHash) {
              this.showPendingUnstake = false
              this.showSuccessUnstake = true
            }

      } catch (error) {
        console.log(error);
      }
    },

    getTimePassed(time){

      // const start = new Date(Number.parseInt(`${time.toString() + "000"}`));
      const now = new Date().getTime() / 1000;

      const diff = now - time;
      const minutes = Math.floor(diff / 60)
      const hours = Math.floor(diff / 3600)
      const days = Math.floor(diff / 86400)


      // const startHours = start.getHours();
      // const startMinutes = start.getMinutes();
      // const startDay = start.getDate();
      // const startMonth = start.getMonth();

      // const nowHours = now.getHours();
      // const nowMinutes = now.getMinutes();
      // const nowDay = now.getDate();
      // const nowMonth = now.getMonth();

      // const passedHours = nowHours - startHours
      // const passedMinutes = nowMinutes - startMinutes
      // const passedDay = nowDay - startDay;
      // const passedMonth = nowMonth - startMonth

    if(minutes < 60){
      return [minutes, (minutes == 1 ? 'min' : 'mins')]
    }else if (hours > 0 && hours < 24){


      return [hours, (hours == 1 ? 'hour' : 'hours')]
    }else if (days > 0) {

      return [days, (days == 1 ? 'day' : 'days')]
    }

    }
    
  },


  mounted() {
    this.lang.init()
    let _this = this;
    window.scrollTo(0, 0)



        setTimeout(async function tick() {
            
            try {

                _this.$root.core.updateUserInfo(_this.currentAddress, 20000)


            } catch (ex) {
            console.log(ex);
            
            setTimeout(tick, 300);
            }
        }, 300);
        }
}
</script>